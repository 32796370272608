// extracted by mini-css-extract-plugin
export var box = "Steps-module--box--1d91d";
export var button = "Steps-module--button--c73ee";
export var checkedItem = "Steps-module--checkedItem--5d924";
export var container = "Steps-module--container--96283";
export var description = "Steps-module--description--1c321";
export var icon = "Steps-module--icon--74fbc";
export var iconWrapper = "Steps-module--iconWrapper--16a8d";
export var item = "Steps-module--item--566d0";
export var itemTitle = "Steps-module--itemTitle--ac4f4";
export var layout = "Steps-module--layout--a2c87";
export var link = "Steps-module--link--d63cd";
export var linkWrapper = "Steps-module--linkWrapper--65711";
export var list = "Steps-module--list--e94a5";
export var onlyList = "Steps-module--onlyList--d8fb7";
export var subtitle = "Steps-module--subtitle--b0f98";
export var subtitleLight = "Steps-module--subtitleLight--47df7";
export var subtitleWidth = "Steps-module--subtitleWidth--f9030";
export var title = "Steps-module--title--f0682";
export var wrapper = "Steps-module--wrapper--ca0f4";