import React, { lazy, Suspense, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Container, Title, Subtitle, Loader } from 'landing/ui';
import { useIsSsr } from 'utils/hooks';
import * as styles from './GetEstimation.module.scss';
import SuccessModal from 'components/Modals/SuccessModal';
import ErrorModal from 'components/Modals/ErrorModal';
const CorporateForm = lazy(() => import('components/Forms/CorporateForm/CorporateForm'));

export function GetEstimation({
  formId = 'get-consultation',
  title = 'Contact us for a free consultation',
  stepTitle = '',
  description,
  chipsIndustry,
  chipsRequest,
}) {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const isSsr = useIsSsr();
  return (
    <Container id="contacts" padding="Without" className={styles.wrapper}>
      <div ref={ref} className={styles.column}>
        <Title className={styles.title} tag="h2">
          {title}
        </Title>
        {description && <Subtitle className={styles.subtitle}>{description}</Subtitle>}
        {inView && !isSsr && (
          <Suspense fallback={<Loader theme="block" />}>
            <CorporateForm
              id={formId}
              className={styles.form}
              onError={() => setError(true)}
              onSuccess={() => setSuccess(true)}
              submitTitle={'Оценить проект'}
              captchaInfo={false}
              formName="get-consultation"
              chipsServices={chipsRequest.items}
              chipsIndustries={chipsIndustry.items}
            />
          </Suspense>
        )}
      </div>
      <SuccessModal
        isOpen={success}
        closeModal={() => {
          setSuccess(false);
        }}
      />
      <ErrorModal isOpen={error} closeModal={() => setError(false)} />
    </Container>
  );
}
