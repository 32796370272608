import React, { lazy, Suspense, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Container, Title, Loader, Image } from 'landing/ui';
import { useIsSsr } from 'utils/hooks';
import * as styles from './GetEstimationSecondary.module.scss';
import cn from 'classnames';
import SuccessModal from 'components/Modals/SuccessModal';
import ErrorModal from 'components/Modals/ErrorModal';
const CorporateFormSecondary = lazy(() =>
  import('components/Forms/CorporateForm/CorporateFormSecondary'),
);

export function GetEstimationSecondary({ formId = 'get-consultation', background }) {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const isSsr = useIsSsr();
  return (
    <Container id="getEstimation" className={styles.container}>
      {background && (
        <Image
          src={background}
          alt="background"
          className={cn(styles.picture)}
          classImage={cn(styles.pictureImage)}
          lazy={false}
        />
      )}
      <div ref={ref} className={styles.wrapper}>
        <div>
          <Title className={styles.title} tag="h2">
            {`Получите бесплатную детальную консультацию по необходимым вам работам`}
          </Title>
          {inView && !isSsr && (
            <Suspense fallback={<Loader theme="block" />}>
              <CorporateFormSecondary
                id={formId}
                className={styles.form}
                onError={() => setError(true)}
                onSuccess={() => setSuccess(true)}
                submitTitle={'Оценить проект'}
                captchaInfo={false}
                formName="get-consultation"
              />
            </Suspense>
          )}
        </div>
      </div>
      <SuccessModal
        isOpen={success}
        closeModal={() => {
          setSuccess(false);
        }}
      />
      <ErrorModal isOpen={error} closeModal={() => setError(false)} />
    </Container>
  );
}
