import React from 'react';

import { Footer, Head, Header } from 'landing/components';
import WhatWeDo from 'components/WhatWeDo';
import Steps from 'components/Steps';
import Services from 'components/Services';
import Intro from 'components/Intro';
import { GetEstimation, GetEstimationSecondary } from 'components/GetConsultation';
import CookiesPolicy from 'components/CookiesPolicy';
import About from 'components/About';

import { anchorLinks } from 'define/anchorLinks';

import data from './data.json';

function Index() {
  return (
    <>
      <Head {...data.head} />
      <Header anchorLinks={anchorLinks} />
      <Intro {...data.intro} />
      <WhatWeDo {...data.whatWeDo} />
      <Services {...data.services} />
      <GetEstimationSecondary {...data.getConsultation} />
      <Steps {...data.steps} />
      <About {...data.about} />
      <GetEstimation {...data.getEstimation} />
      <Footer {...data.footer} />
      <CookiesPolicy />
    </>
  );
}

export default Index;
