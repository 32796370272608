import React from 'react';
import { Subtitle, Modal, CloseButton, Icon } from 'landing/ui';
import * as styles from './ErrorModal.module.scss';

export default function ErrorModal({ isOpen, closeModal }) {
  return (
    <Modal
      isOpen={isOpen}
      closeButton={false}
      animation="opacity"
      closeModalCallback={closeModal}
      theme="error"
    >
      {onClose => (
        <>
          <CloseButton onClose={onClose} />
          <div>
            <div className={styles.iconWrapper}>
              <div className={styles.icon}>
                <Icon name={'popup-failure'} />
              </div>
            </div>
            <Subtitle size="xlg" className={styles.text}>
              {'Запрос не был отправлен.\nПожалуйста, попробуйте снова или отправьте на'}
            </Subtitle>
            <Subtitle size="xlg" className={styles.contact}>
              <a href="mailto:info@advisoritdev.ru" className={styles.link}>
                info@advisoritdev.ru
              </a>
            </Subtitle>
          </div>
        </>
      )}
    </Modal>
  );
}
