// extracted by mini-css-extract-plugin
export var box = "About-module--box--8c118";
export var contact = "About-module--contact--b897d";
export var contactsWrapper = "About-module--contactsWrapper--229c0";
export var description = "About-module--description--9cfca";
export var icon = "About-module--icon--8b8b1";
export var iconWrapper = "About-module--iconWrapper--8f942";
export var itemImage = "About-module--itemImage--63547";
export var itemTitle = "About-module--itemTitle--bdec4";
export var logo = "About-module--logo--4231d";
export var text = "About-module--text--d67a4";
export var title = "About-module--title--f1024";
export var wrapper = "About-module--wrapper--63b55";