export const anchorLinks = [
  {
    anchor: 'services',
    alias: '#services',
    title: 'Наши услуги',
  },
  {
    anchor: 'steps',
    alias: '#steps',
    title: 'Этапы сотрудничества',
  },
  {
    anchor: 'contacts',
    alias: '#contacts',
    title: 'Контакты',
  },
];
