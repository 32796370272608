import React from 'react';
import cn from 'classnames';

import { Container, Image, Icon, Markup } from 'landing/ui';

import * as styles from './About.module.scss';

export default function About({ title, company, icon, contacts, img }) {
  return (
    <Container background="variable" id="about">
      <div className={styles.wrapper}>
        <div className={styles.text}>
          <p className={styles.title}>{title}</p>
          <WrappedIcon className={styles.logo} icon={icon} />
          <p className={styles.itemTitle}>{company.title}</p>
          <Markup tag="p" className={styles.description}>
            {company.description}
          </Markup>
          <Contacts {...contacts} />
        </div>
        <div className={cn(styles.itemImage)}>
          <Image src={img.src} alt="background" lazy />
        </div>
      </div>
    </Container>
  );
}

const WrappedIcon = ({ icon, className }) => (
  <div className={styles.iconWrapper}>
    <div className={cn(className, styles.icon)}>
      <Icon name={icon} />
    </div>
  </div>
);

const Contacts = ({ title, icons }) => {
  const ContactWrapper = ({ href, children }) => {
    let tag;

    if (href) {
      tag = 'a';
    } else {
      tag = 'div';
    }

    return (
      <Markup tag={tag} href={href} className={styles.description}>
        {children}
      </Markup>
    );
  };

  return (
    <div className={styles.contactsWrapper}>
      <p className={styles.itemTitle}>{title}</p>
      <div>
        {icons.map(({ img, description, href }) => (
          <div className={styles.contact} key={img}>
            <WrappedIcon icon={img} />
            <ContactWrapper href={href}>{description}</ContactWrapper>
          </div>
        ))}
      </div>
    </div>
  );
};
