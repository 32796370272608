import React from 'react';
import cn from 'classnames';

import { Container, Title, Markup, Image } from 'landing/ui';
import ServicesGrid from './ServicesGrid';
import ServicesSlider from './ServicesSlider';
import * as styles from './Services.module.scss';

export default function Services({ title, items, background = 'white', id = 'services' }) {
  return (
    <>
      <Container background={background} id={id} className={styles.container}>
        <div className={styles.wrapper}>
          <Title tag="h2" className={cn(styles.title)}>
            {title}
          </Title>
          <div className={styles.layout}>
            <ServicesGrid Card={ServicesItem} items={items} />
            <ServicesSlider Card={ServicesItem} items={items} />
          </div>
        </div>
      </Container>
    </>
  );
}

const ServicesItem = ({ theme, title, description, img }) => {
  return (
    <div className={cn(styles.item, styles[`${theme}TH`])}>
      <div className={styles.textWrapper}>
        <Title tag="h3" size="h4" color="white" className={styles.itemTitle}>
          {title}
        </Title>
        {description && (
          <Markup className={styles.description} tag="p">
            {description}
          </Markup>
        )}
      </div>

      <div className={cn(styles.itemImage)}>
        <Image src={img} alt="background" lazy />
      </div>
    </div>
  );
};
