import React from 'react';
import { Subtitle, Modal, CloseButton, Icon } from 'landing/ui';
import * as styles from './SuccessModal.module.scss';

export default function SuccessModal({ isOpen, closeModal }) {
  return (
    <Modal
      isOpen={isOpen}
      closeButton={false}
      animation="opacity"
      closeModalCallback={closeModal}
      theme="error"
    >
      {onClose => (
        <>
          <CloseButton onClose={onClose} />
          <div>
            <div className={styles.iconWrapper}>
              <div className={styles.icon}>
                <Icon name={'popup-success'} />
              </div>
            </div>
            <Subtitle size="xlg" className={styles.text}>
              {'Ваше сообщение отправлено. \nМы свяжемся с вами в ближайшее время.'}
            </Subtitle>
          </div>
        </>
      )}
    </Modal>
  );
}
