// extracted by mini-css-extract-plugin
export var box = "Services-module--box--fb9f6";
export var description = "Services-module--description--142d8";
export var grayTH = "Services-module--grayTH--afaa4";
export var item = "Services-module--item--29b7a";
export var itemImage = "Services-module--itemImage--764b3";
export var itemTitle = "Services-module--itemTitle--9e25b";
export var layout = "Services-module--layout--0711e";
export var lightTH = "Services-module--lightTH--519cf";
export var textWrapper = "Services-module--textWrapper--f1b07";
export var title = "Services-module--title--aa4d2";
export var wrapper = "Services-module--wrapper--2ddd9";