// extracted by mini-css-extract-plugin
export var accordion = "GetEstimation-module--accordion--86d69";
export var box = "GetEstimation-module--box--8e1c7";
export var btnContainer = "GetEstimation-module--btnContainer--69acb";
export var captchaLeft = "GetEstimation-module--captchaLeft--a8f69";
export var captchaRight = "GetEstimation-module--captchaRight--6ca2f";
export var column = "GetEstimation-module--column--7b3e0";
export var contact = "GetEstimation-module--contact--480a4";
export var contactsField = "GetEstimation-module--contactsField--5c865";
export var contactsValue = "GetEstimation-module--contactsValue--72c80";
export var form = "GetEstimation-module--form--8771d";
export var mobileContacts = "GetEstimation-module--mobileContacts--4b2d6";
export var slider = "GetEstimation-module--slider--ff230";
export var subtitle = "GetEstimation-module--subtitle--87eef";
export var title = "GetEstimation-module--title--feb15";
export var wrapper = "GetEstimation-module--wrapper--b62f3";