// extracted by mini-css-extract-plugin
export var accordion = "GetEstimationSecondary-module--accordion--487eb";
export var box = "GetEstimationSecondary-module--box--caf88";
export var btnContainer = "GetEstimationSecondary-module--btnContainer--71faf";
export var captchaLeft = "GetEstimationSecondary-module--captchaLeft--9a5b5";
export var captchaRight = "GetEstimationSecondary-module--captchaRight--32b22";
export var column = "GetEstimationSecondary-module--column--cc1e3";
export var columnWrapper = "GetEstimationSecondary-module--columnWrapper--a514f";
export var contact = "GetEstimationSecondary-module--contact--b94d3";
export var contactsField = "GetEstimationSecondary-module--contactsField--71c23";
export var contactsValue = "GetEstimationSecondary-module--contactsValue--5bf09";
export var container = "GetEstimationSecondary-module--container--dd48d";
export var form = "GetEstimationSecondary-module--form--2d177";
export var icon = "GetEstimationSecondary-module--icon--cdaab";
export var iconMobile = "GetEstimationSecondary-module--iconMobile--fada9";
export var img = "GetEstimationSecondary-module--img--29034";
export var mobileContacts = "GetEstimationSecondary-module--mobileContacts--3dbc6";
export var picture = "GetEstimationSecondary-module--picture--42824";
export var pictureImage = "GetEstimationSecondary-module--pictureImage--ca26b";
export var slider = "GetEstimationSecondary-module--slider--8b212";
export var subtitle = "GetEstimationSecondary-module--subtitle--7095d";
export var title = "GetEstimationSecondary-module--title--f193a";
export var wrapper = "GetEstimationSecondary-module--wrapper--a39c9";